import React from 'react'

export const Download = ({ color, style }) =>
  <svg width="11px" height="8px" viewBox="0 0 11 8" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink" style={style}>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Image-Card" transform="translate(-396.000000, -293.000000)" fill={ color || "#3AACA2" }
      fillRule="nonzero">
        <g id="Group-2" transform="translate(0.000000, 0.000000)">
          <path
            d="M405.814,296.696 C405.866,296.518 405.9,296.339 405.9,296.143 C405.9,295.054 405.023,294.143 403.975,294.143 C403.683,294.143 403.408,294.214 403.15,294.339 C402.6,293.536 401.689,293 400.675,293 C399.042,293 397.719,294.339 397.65,296.018 C396.67,296.375 396,297.339 396,298.429 C396,299.857 397.1,301 398.475,301 L404.8,301 C406.003,301 407,299.982 407,298.714 C407,297.875 406.553,297.107 405.814,296.696 Z M404.8,300.429 L398.475,300.429 C397.409,300.429 396.55,299.536 396.55,298.429 C396.55,297.429 397.272,296.589 398.217,296.446 C398.2,296.357 398.2,296.25 398.2,296.143 C398.2,294.732 399.3,293.571 400.675,293.571 C401.706,293.571 402.583,294.232 402.961,295.179 C403.219,294.893 403.562,294.714 403.975,294.714 C404.731,294.714 405.35,295.357 405.35,296.143 C405.35,296.482 405.23,296.786 405.058,297.036 C405.848,297.161 406.45,297.875 406.45,298.714 C406.45,299.661 405.711,300.429 404.8,300.429 Z M402.961,296.982 C402.875,296.893 402.738,296.893 402.669,296.982 L401.5,298.196 L401.5,295.5 C401.5,295.393 401.397,295.286 401.294,295.286 L401.156,295.286 C401.036,295.286 400.95,295.393 400.95,295.5 L400.95,298.196 L399.764,296.982 C399.695,296.893 399.558,296.893 399.472,296.982 L399.386,297.089 C399.3,297.161 399.3,297.304 399.386,297.393 L401.07,299.143 C401.156,299.232 401.277,299.232 401.363,299.143 L403.047,297.393 C403.133,297.304 403.133,297.161 403.047,297.089 L402.961,296.982 Z" id="Shape" />
        </g>
      </g>
    </g>
  </svg>