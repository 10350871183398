import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Download } from "../components/download"

import searchIcon from '../components/search.svg'
import arrowLeft from '../components/arrow-left.svg'
import arrowRight from '../components/arrow-right.svg'
import close from '../components/close.svg'

import shareImage from '../images/facebook_share.jpg'

import styled from 'styled-components'
import twitter from "../components/t.svg"
import facebook from "../components/f.svg"

const Grid = styled.section`
    display: grid;
    grid-column-gap: 60px;
    grid-gap: 30px;
    padding: 60px 20px;
    max-width: 1640px;
    margin: auto;
    
    @media (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 60px;
    }
    
    @media (min-width: 769px) and (max-width: 1023px) {
      grid-template-columns: repeat(2, 1fr);
    }
`

export const Image = styled.div`
  background-image: url('${props => props.src}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 66.66667%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    transition: all 0.25s ease-in-out;
  }
`

const ModalLink = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 10000px;
  background-color: #76CDC5;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1;
  left: calc(50% - 23px);
  top: calc(50% - 23px);
  opacity: 0;
  transition: all 0.25s ease-in-out;
  
  img {
    margin-bottom: 0;
    position: relative;
    left: 1px;
  }
`

const ImageCard = styled.div`
  position: relative;
  display: block;
  
  &:hover {
    cursor: pointer;
    
    ${ModalLink} {
      opacity: 1;
      transition: all 0.25s ease-in-out;
    }
    
    ${Image} {
      transition: all 0.25s ease-in-out;
      
      &:after {
        background-color: rgba(58, 172, 162, 0.7);
      }
    }
  }
`

const ModalStyles = styled.div`
  // display: flex;
  position: fixed;
  top: 0;
  bottom: 80px;
  left: 0;
  right: 0;
  background-color: rgba(58, 172, 162, 0.9);
  z-index: 10;
  justify-content: center;
`

const ModalContent = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: auto;
`

export const ActionButton = styled.button`
  width: 46px;
  height: 46px;
  border-radius: 10000px;
  background-color: #76CDC5;
  display: flex;
  justify-content: center;
  transition: all 0.25s ease-in-out;
  appearance: none;
  border: none;
  
  &:active,
  &:focus {
    outline: none;
  }
  
  &:disabled {
    background-color: rgba(19, 19, 19, 0.75);
  }
  
  img {
    margin-bottom: 0;
    position: relative;
    left: 1px;
    // height: 20px;
    // width: auto;
  }
  
  &:hover:not(:disabled) {
    cursor: pointer;
    background-color: rgba(58, 172, 162, 0.7);
    transition: all 0.25s ease-in-out;
  }
`

export const NavigationBar = styled.div`
  display: grid;
  padding: 20px 0 0;
  grid-gap: 10px;
  grid-template-columns: 46px 46px 46px 46px 46px 46px;
  align-items: center;
  margin: auto;
  
 
  @media (max-width: 580px) { 
    .full {
       grid-column: 1 / 7; 
       margin-top: 20px;
       text-align: center !important;
    }
  }
  
  @media (min-width: 581px) {
    grid-template-columns: 46px 46px 46px 46px 46px 46px auto;
  }
`

// https://secondchance.imgix.net/secondchancechurch/carpool_test/DSC06401.jpg?mark=https://secondchance.imgix.net/secondchancechurch/carpool_test/mothers_day_frame.png&mark-align=center,middle

// https://secondchance.imgix.net/secondchancechurch/carpool_test/DSC06401.jpg?ar=5:4&w=3000&fit=crop&q=100&dpi=300&mark=https://secondchance.imgix.net/secondchancechurch/carpool_test/mothers_day_frame-8x10.png&mark-align=center,middle

const GET_IMAGES = graphql`{
  images: allS3Image(filter: {id: {ne: "s3-image-border/mothers_day_frame-8x10.png"}}) {
    totalCount
    edges {
      node {
        id
        Key
        Extension
      }
    }
  }
}`

export const Button = styled.a`
  font-family: fairwater-script, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: ${props => props.light ? '#fff' : '#3AACA2'};
  text-align: right;
  display: ${props => props.inline ? 'inline-block' : 'block'};
  margin-top: ${props => props.flush ? 'none' : '10px'};
  padding: 0 10px;
  font-size: 20px;
  transition: all 0.25s ease-in-out;
  border-bottom: ${props => props.light ? '1px solid transparent' : 'none'};
  line-height: 1;
  text-decoration: none;
  
  svg * {
    transition: all 0.25s ease-in-out;
  }
  
  &:hover {
    color: ${props => props.light ? '#fff' : '#236761'};
    transition: all 0.25s ease-in-out;
    border-bottom: ${props => props.light ? '1px solid' : 'none'};
    
    svg * {
      fill: ${props => props.light ? '#fff' : '#236761'};
      transition: all 0.25s ease-in-out;
    }
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`

// let fathom = null

const IndexPage = () => {
  const [ currentImage, setCurrentImage ] = useState(0)
  const [ showModal, setShowModal ] = useState(false)

  const { images } = useStaticQuery(GET_IMAGES)

  const openModal = (image) => {
    setCurrentImage(image)
    setShowModal(true)
  }

  const closeModal = () => setShowModal(false)

  return (
      <Layout>
        <SEO
          title={'Carpool Portraits | Second Chance Church'}
          image={`https://carpoolportraits.com/${shareImage}`}
        />
        <Grid>
          {images.edges.map((image, i) => (
            <div>
              <ImageCard key={i} onClick={() => openModal(i)}>
                <ModalLink>
                  <img src={searchIcon} />
                </ModalLink>
                <Image src={`https://carpoolportraits.imgix.net/${image.node.Key}?w=1000&ar=5:4&fit=crop`} />
              </ImageCard>
              <Button
                href={`https://carpoolportraits.imgix.net/${image.node.Key}?ar=5:4&w=3000&fit=crop&q=100&dpi=300&mark=https://carpoolportraits.imgix.net/border/mothers_day_frame-8x10.png&mark-align=center,middle&dl=${image.node.Key}`}
                download={images.edges[currentImage].node.Key}
                onClick={() => fathom('trackGoal', 'TBAUSPNA', 0)}
                target={'_blank'}
              >
                download <Download style={{ height: 13, width: 'auto' }} />
              </Button>
            </div>
          ))}
        </Grid>
        {showModal &&
          <ModalStyles>
            <Flex>
              <div
                onClick={() => setShowModal(false)}
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10
                }}
              />
              <ModalContent style={{ position: 'relative', zIndex: 20, padding: 20 }}>
                <Image
                  src={`https://carpoolportraits.imgix.net/${images.edges[currentImage].node.Key}?w=1600&ar=5:4&fit=crop`}
                  alt=""/>
                <NavigationBar>
                  <ActionButton onClick={() => setCurrentImage(currentImage - 1)} disabled={currentImage === 0 ? true : null}>
                    <img src={arrowLeft} style={{ left: 0 }} alt=""/>
                  </ActionButton>
                  <ActionButton onClick={() => setCurrentImage(currentImage + 1)} disabled={currentImage === images.edges.length - 1}>
                    <img src={arrowRight} style={{ left: 0 }} alt=""/>
                  </ActionButton>
                  <ActionButton onClick={closeModal}>
                    <img src={close} style={{ left: 0 }} alt=""/>
                  </ActionButton>
                  <div />
                  <ActionButton
                    as={'a'}
                    href={`http://twitter.com/share?text=View our Mothers Day Carpool Portrait taken at Second Chance Church!&url=https://carpoolportraits.com/${images.edges[currentImage].node.Key.replace(`.${images.edges[currentImage].node.Extension}`, '')}&hashtags=CarpoolPortraits`}
                    target={'_blank'}
                  >
                    <img src={twitter} style={{ left: 0 }} alt="Share to Twitter"/>
                  </ActionButton>
                  <ActionButton
                    as={'a'}
                    href={`https://www.facebook.com/sharer/sharer.php?u=https://carpoolportraits.com/${images.edges[currentImage].node.Key.replace(`.${images.edges[currentImage].node.Extension}`, '')}`} target="_blank">
                    <img src={facebook} style={{ left: 0 }} alt="Share to Facebook" />
                  </ActionButton>
                  <div className={'full'} style={{ textAlign: 'right' }}>
                    <Button
                      flush
                      inline
                      light
                      href={`https://carpoolportraits.imgix.net/${images.edges[currentImage].node.Key}?ar=5:4&w=3000&fit=crop&q=100&dpi=300&mark=https://carpoolportraits.imgix.net/border/mothers_day_frame-8x10.png&mark-align=center,middle&dl=${images.edges[currentImage].node.Key}`}
                      download={images.edges[currentImage].node.Key}
                      onClick={() => fathom('trackGoal', 'TBAUSPNA', 0)}
                      target={'_blank'}
                    >
                      download <Download color={'#fff'} style={{ height: 13, width: 'auto' }} />
                    </Button>
                  </div>
                </NavigationBar>
              </ModalContent>
            </Flex>
          </ModalStyles>
        }
      </Layout>
    )
  }

export default IndexPage
